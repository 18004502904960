<template>
  <div class="cgv">
    <Banner>
      <div class="banner-content">
        <MeepIconCgv
          class="banner-content__icon banner-content__icon-inverted"
        />
        <span class="banner-content__text">{{ $t("cgv.banner") }}</span>
      </div>
    </Banner>
    <PageHeader :title="$t('cgv.title')" :has-back="true" @back="backToHome" />
    <div class="cgv-content page-layout">
      <div class=" md-alignment-center-center">
        <md-card class="cgv__card md-layout-item md-size-80">
          <md-card-content v-if="isGC">
            L’application Groupe Conseils est un produit commercialisé et détenu
            par Expert SA. Rendez-vous sur le site
            <a href="https://www.expertsa.fr">www.expertsa.fr</a> pour consulter
            les conditions générales de vente.
          </md-card-content>

          <md-card-content v-else>
            <span class="md-primary--inverted cgv__title">MEEP</span> : mon
            expert en poche est un produit commercialisé par Expert SA.
            Rendez-vous sur le site
            <a href="https://www.expertsa.fr" target="_blank"
              >www.expertsa.fr
            </a>
            pour consulter les conditions générales de vente.
          </md-card-content>
        </md-card>
      </div>
    </div>
  </div>
</template>
<script>
import PageHeader from "../components/PageHeader";
import { mapGetters } from "vuex";
import Banner from "@/components/Banner";
import MeepIconCgv from "@/components/icons/MeepIconCgv.vue";
import HasBackMixin from "@/mixins/HasBackMixin";

export default {
  name: "CGV",

  components: {
    PageHeader,
    Banner,
    MeepIconCgv,
  },

  mixins: [HasBackMixin],

  computed: {
    ...mapGetters(["isGC"]),
  },

  mounted() {
    this.$scrollTo(".cgv__card");
  },
};
</script>

<style lang="scss" scoped>
.cgv {
  &__card {
    border-radius: 10px;
    padding: 50px 40px;

    .md-card-content {
      padding: 0;
      font-size: 17px;
    }
  }

  &__title {
    font-weight: bold;
  }
}
</style>
